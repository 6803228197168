<template>
  <div>
    <!--begin::Card-->
    <div class="card-body detailpage-touchless">
      <!--begin::Details-->
      <div class="d-flex">
        <!--begin: Pic-->
        <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
          <div class="symbol symbol-50 symbol-lg-120">
            <v-img
              class="profile-image"
              width="100"
              height="100"
              :lazy-src="$defaultProfileImage"
              :src="getProfileImage"
            >
            </v-img>
          </div>

          <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
            <span class="font-size-h3 symbol-label font-weight-boldest">{{
              currentUser.display_name
                ? currentUser.display_name.charAt(0).toUpperCase()
                : ""
            }}</span>
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div class="d-flex justify-content-between flex-wrap mt-1">
            <div class="d-flex mr-3">
              <a
                href="#"
                class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                >{{ currentUser.full_name }}</a
              >
              <template v-if="currentUser.activated">
                <a
                  href="#"
                  :content="`<span class=&quot;font-size-13&quot;>Account is activated</span>`"
                  v-tippy="{ arrow: true, animation: 'fade' }"
                  ><i class="flaticon2-correct text-success font-size-h5"></i
                ></a>
              </template>
            </div>
          </div>
          <!--end::Title-->

          <!--begin::Content-->
          <div class="d-flex flex-wrap justify-content-between mt-1">
            <div class="d-flex flex-column flex-grow-1 pr-8">
              <div class="d-flex flex-wrap mb-4">
                <a
                  :content="`<span class=&quot;font-size-13&quot;>User Email</span>`"
                  v-tippy="{ arrow: true, animation: 'fade' }"
                  :href="'mailto:' + currentUser.user_email"
                  class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  ><i class="flaticon2-new-email mr-2 font-size-lg"></i
                  >{{ currentUser.user_email }}</a
                >
                <a
                  :content="`<span class=&quot;font-size-13&quot;>User Name</span>`"
                  v-tippy="{ arrow: true, animation: 'fade' }"
                  href="#"
                  class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  ><i class="flaticon2-user mr-2 font-size-lg"></i
                  >{{ currentUser.user_name }}</a
                >
                <a
                  :content="`<span class=&quot;font-size-13&quot;>Phone Number</span>`"
                  v-tippy="{ arrow: true, animation: 'fade' }"
                  :href="'tel:' + currentUser.phone_number"
                  class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  ><i class="flaticon2-phone mr-2 font-size-lg"></i
                  >{{ currentUser.phone_number }}</a
                >
                <a
                  :content="`<span class=&quot;font-size-13&quot;>User Role</span>`"
                  v-tippy="{ arrow: true, animation: 'fade' }"
                  href="#"
                  class="text-dark-50 text-hover-primary text-uppercase font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  ><i class="flaticon2-calendar-3 mr-2 font-size-lg"></i
                  >{{ currentUser.role_name }}
                </a>
              </div>
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->
      <!--begin::Profile-->
      <v-tabs
        v-model="userTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#tab-' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 89vh position: relative"
      >
        <v-tabs-items v-model="userTab" touchless>
          <v-tab-item :value="'tab-history'">
            <RecentActivity :user="userId"></RecentActivity>
          </v-tab-item>

          <v-tab-item value="tab-signature">
            <br />
            <v-container class="ml-4">
              <v-layout style="max-width: 50%">
                <v-flex md6>
                  <v-img
                    contain
                    v-if="attachment_url"
                    :lazy-src="$defaultImage"
                    :src="attachment_url"
                  ></v-img>
                  <v-img
                    v-else
                    contain
                    :lazy-src="$defaultImage"
                    :src="$defaultImage"
                  ></v-img>
                  <br />
                  <v-btn
                    depressed
                    v-on:click="select_file()"
                    color="cyan white--text"
                    class="custom-bold-button"
                    label
                    >Change</v-btn
                  >
                  <v-file-input
                    ref="select-file"
                    class="d-none"
                    accept="image/png, image/jpeg, image/jpg"
                    v-model="signature"
                    dense
                    v-on:change="on_file_change($event)"
                    hide-details
                  ></v-file-input>
                </v-flex>
                <v-flex md6> </v-flex>
              </v-layout>
              <br />
              <v-layout
                class="signature-pad"
                id="signature-pad"
                style="max-width: 50%"
              >
                <div
                  ref="customer_signature_div"
                  style="width: 280px"
                  class="custom-border-grey-dashed signature-pad-wrap"
                >
                  <canvas
                    ref="customer_signature"
                    style="width: 280px; height: 120px !important"
                  ></canvas>
                </div>
                <hr />
                <div style="position: relative">
                  <v-btn
                    style="position: absolute; bottom: 0px; right: -14px"
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    v-on:click="customer_signature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </div> </v-layout
              ><br />
              <v-layout style="max-width: 50%">
                <v-btn
                  depressed
                  v-on:click="submitSignature"
                  color="cyan white--text"
                  class="custom-bold-button"
                  :disabled="valid_signature"
                  >Submit</v-btn
                >
              </v-layout>
            </v-container>
          </v-tab-item>

          <v-tab-item :value="'tab-password'">
            <PasswordUpdate
              isOldPassword
              :user="userId"
              v-on:password:updated="logoutUser"
            ></PasswordUpdate>
          </v-tab-item>
          <v-tab-item :value="'tab-login-log'">
            <LoginLog :user="userId" :logs="loginLogs"></LoginLog>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>
      <!--end::Profile-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LoginLog from "@/view/pages/user/detail/LoginLog";
import PasswordUpdate from "@/view/pages/user/detail/PasswordUpdate";
import RecentActivity from "@/view/pages/user/detail/RecentActivity";
//import { GET } from "@/core/services/store/request.module";
import { GET, PATCH, UPLOAD, PUT } from "@/core/services/store/request.module";
import JwtService from "@/core/services/jwt.service";
import SignaturePad from "signature_pad";
import ObjectPath from "object-path";

export default {
  name: "basic-profile",
  data() {
    return {
      userId: 0,
      userTab: "tab-signature",
      pageLoading: true,
      currentUser: new Object(),
      userData: new Object(),
      loginLogs: [],
      signature: null,
      customer_signature: null,
      attachment_url: null,
      tabs: [
        {
          title: "Recent Activities",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
        {
          title: "Signature",
          icon: "mdi-credit-card",
          key: "signature",
          disabled: false,
        },
        {
          title: "Login Log",
          icon: "mdi-credit-card",
          key: "login-log",
          disabled: false,
        },
        {
          title: "Password",
          icon: "mdi-credit-card",
          key: "password",
          disabled: false,
        },
      ],
    };
  },
  components: {
    LoginLog,
    PasswordUpdate,
    RecentActivity,
  },
  methods: {
    select_file() {
      this.$refs["select-file"].$refs["input"].click();
    },
    on_file_change(file) {
      if (file) {
        this.attachment_url = URL.createObjectURL(file);
        let formData = new FormData();
        formData.append("files[0]", file, file.name);
        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
          .then((response) => {
            this.attachment_url = ObjectPath.get(response, "data.0.file.url");
            this.$refs["select-file"].reset();
            this.update_signature();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    update_signature() {
      this.$store
        .dispatch(PATCH, {
          url: `user/${this.userId}/signature/update`,
          data: { signature: this.attachment_url },
        })
        .catch((error) => {
          this.logError(error);
        });
    },

    submitSignature() {
      const _this = this;

      if (_this.customer_signature.isEmpty()) {
        return false;
      }
      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(PUT, {
          url: `user/${this.userId}/signature/update`,
          data: {
            customer: _this.customer_signature.toDataURL(),
          },
        })
        .then(() => {
          //this.getUser();
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.loadContent();
          _this.customer_signature.clear();
        });
    },

    logoutUser() {
      JwtService.destroyAuth();
    },
    getUserProfile() {
      const _this = this;
      try {
        _this.currentUser = JwtService.currentUser();
        _this.userId = _this.currentUser.id;
      } catch (error) {
        _this.logError(error);
      }
    },
    getUser() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "user/" + _this.userId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    loadContent() {
      const _this = this;
      _this.pageLoading = true;
      _this
        .getUser()
        .then((response) => {
          _this.userData = response;
          _this.attachment_url = response.signature;
          _this.loginLogs = response.login_details || [];
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    initSignature() {
      const _this = this;
      _this.$nextTick(() => {
        let ccanvas = _this.$refs["customer_signature"];
        let ccanvasDiv = _this.$refs["customer_signature_div"];
        // let cparentDiv = document.getElementById("signature-pad");
        let cparentWidth = 280; //cparentDiv.offsetWidth / 2;
        ccanvas.setAttribute("width", cparentWidth);
        ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");
        _this.customer_signature = new SignaturePad(ccanvas);
      });
    },
  },
  mounted() {
    this.getUserProfile();
    this.loadContent();
    this.initSignature();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Profile" },
      { title: "Basic" },
    ]);
  },
  computed: {
    getProfileImage() {
      if (
        this.lodash.isEmpty(this.currentUser) === false &&
        this.lodash.isEmpty(this.currentUser.profile_logo) === false
      ) {
        return this.currentUser.profile_logo.file.url;
      }
      return null;
    },
    valid_signature() {
      return this.customer_signature
        ? this.customer_signature.isEmpty()
        : false;
    },
  },
};
</script>
